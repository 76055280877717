import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { debounce } from '../../shared/debounce'
import { clearActiveMarkers, clearHoveredMarkers } from '../../services/markerService'
import { updateLocator, viewLocationDetails } from '../../actions/locatorActions';

import LocatorEmptyState from './LocatorEmptyState';
import LocatorFilterSelect from './LocatorFilterSelect';
import LocatorListItem from './LocatorListItem';
import LocatorListSearch from './LocatorListSearch';
import LocatorSpinner from './LocatorSpinner';
import LocatorDelinquentNotice from './LocatorDelinquentNotice';
import LocatorResultsSummary from './LocatorResultsSummary';

export class LocatorList extends Component {
  state = {
    locationsScrolledAmount: 0,
    setScrollPostion: false,
  }

  componentDidMount = () => {
    this.scrollHandler = debounce(this.handleScroll, 350);

    if (this.locatorList) this.locatorList.addEventListener('scroll', this.scrollHandler);
  }

  componentWillUnmount = () => {
    if (this.locatorList) {
      if (this.scrollHandler) this.locatorList.removeEventListener('scroll', this.scrollHandler);
    }
  }

  componentDidUpdate() {
    const { location } = this.props.locator;

    if (!location && this.locatorList && this.state.setScrollPostion) {
      this.locatorList.scrollTop = this.state.locationsScrolledAmount;
      this.locatorList.addEventListener('scroll', this.scrollHandler)
      this.setState({ setScrollPostion: false })
      clearActiveMarkers()

    } else if (location && !this.state.setScrollPostion) {
      this.setState({ setScrollPostion: true })
      clearHoveredMarkers()
    }
  }

  handleScroll = () => {
    const locationsScrolledAmount = this.locatorList ? this.locatorList.scrollTop : 0
    this.setState({ locationsScrolledAmount })
  }

  renderLoading() {
    return (
      <div className="closeby-locations-fetching">
        <LocatorSpinner />
      </div>
    )
  }

  renderErrorMessage() {
    const { errorMessage } = this.props.locator
    return (
      <div className="closeby-locations-error">
        <p>{errorMessage}</p>
      </div>
    )
  }

  renderLocations() {
    const {
      errorMessage,
      isFetching,
      googleAnalyticsId,
      listStyle,
      locations,
      mapKey,
      text_overrides,
      locationDefaultImage,
      categoryColors,
      categoryLocationImagesEnabled
    } = this.props.locator;

    if (errorMessage) {
      return (
        <div>
          {this.renderErrorMessage()}
        </div>
      )
    } else if (isFetching) {
      return (
        <div>
          {this.renderLoading()}
        </div>
      )
    } else if (locations.length === 0) {
      return <LocatorEmptyState text_overrides={text_overrides} />;
    } else {
      return (
        <div className={`closeby-locations-list-items ${listStyle}`}>
          {locations.map((location) => {
            return (
              <LocatorListItem
                key={location.id}
                location={location}
                listStyle={listStyle}
                locationDefaultImage={locationDefaultImage}
                categoryColors={categoryColors}
                categoryLocationImagesEnabled={categoryLocationImagesEnabled}
                onClick={this.props.viewLocationDetails(mapKey, location, googleAnalyticsId)}
              />
            )
          })}
        </div>
      )
    }
  }

  render() {
    const {
      embed: {
        pageEmbed,
      },
      locator: {
        categoryRestriction,
        delinquent,
        listStyle,
        location
      },
      mapboxClientKey,
      onSearch,
    } = this.props;

    if (location && !delinquent) return null;

    return (
      <div className={`closeby-locations-wrapper ${pageEmbed ? 'page-embed' : ''} ${listStyle}`}>
        {delinquent ? <LocatorDelinquentNotice/> : (
          <div>
            <LocatorListSearch mapboxClientKey={mapboxClientKey} onSearch={onSearch} />

            <div className={`closeby-locations-list ${categoryRestriction ? 'with-filter-restriction' : ''}`} ref={el => this.locatorList = el}>
              <LocatorFilterSelect onSearch={onSearch} />
              {this.renderLocations()}

              <LocatorResultsSummary/>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    locator: state.locator,
    embed: state.embed
  }
};

export default connect(mapStateToProps, {
  updateLocator,
  viewLocationDetails
})(LocatorList);
