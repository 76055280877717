import queryString from 'query-string'

export default function (urlString, params) {
  if (!urlString || urlString === '') {
    return urlString
  }
  params = params || {}
  let url
  try {
    url = new URL(urlString)
  } catch (e) {
    return urlString
  }
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return url.toString()
}
