import {
  GET_STATS_SUGGESTIONS,
  GET_STATS_DEVICES,
  GET_STATS_VIEWS
} from '../constants';

import createReducer from '../shared/createReducer';

const statsReducer = createReducer(
  'stats',
  {
    STATS_UPDATE_VALUES: (state, action) => {
      return {
        ...state,
        ...action.values
      }
    },
    STATS_UPDATE_RECEIVE: (state, action) => {
      return {
        ...state,
        isSaving: false
      }
    },
    GET_STATS_SUGGESTIONS: (state, action) => {
      let stats = {
        ...action.json,
        isFetchingSuggestions: false
      }
      return {
        ...state,
        ...stats
      }
    },
    GET_STATS_VIEWS: (state, action) => {
      let stats = {
        ...action.json,
        isFetchingViews: false
      }
      return {
        ...state,
        ...stats
      }
    },
    GET_STATS_DEVICES: (state, action) => {
      let stats = {
        devices: action.json.devices,
        devicesTotalCount: action.json.total_device_views,
        isFetchingDevices: false,
      }
      return {
        ...state,
        ...stats
      }
    }
  }, {
    isSaving: false
  }
);

export default statsReducer;
