import { STORE_INITIALIZE } from '../constants';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import { apiLightMiddleware } from '../middleware/apiLight';

const middlewares = [
  thunk,
  apiLightMiddleware
];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger();
  middlewares.push(logger);
}

export const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middlewares),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

if (!window.store) {
  window.store = store;
}

export default store;

export const stateInitializer = (state = {}) => {
  store.dispatch({ type: STORE_INITIALIZE, payload: state });
}
