export const clearActiveMarkers = () => {
  const markerEls = document.querySelectorAll('[data-marker-icon-id]')

  if (markerEls && markerEls.length) {
    markerEls.forEach(markerEl => {
      markerEl.classList.remove('active')

      const markerParent = markerEl.closest('.closeby-marker')
      if (markerParent) markerParent.classList.remove('active')
    })
  }
}

export const clearHoveredMarkers = () => {
  const markerEls = document.querySelectorAll('[data-marker-icon-id]')

  markerEls.forEach(markerEl => {
    markerEl.classList.remove('hover')

    const markerParent = markerEl.closest('.closeby-marker')
    if (markerParent) markerParent.classList.remove('hover')
  })
}

export const handleLocationMouseover = (location) => {
  if (location) {
    const markerEl = document.querySelector(`[data-marker-icon-id="${location.id}"]`)

    if (markerEl) {
      markerEl.classList.add('hover');

      const markerParent = markerEl.closest('.closeby-marker')
      if (markerParent) markerParent.classList.add('hover')
    }
  }
}

export const handleLocationMouseout = (location) => {
  if (location) {
    const markerEl = document.querySelector(`[data-marker-icon-id="${location.id}"]`)

    if (markerEl) {
      markerEl.classList.remove('hover');

      const markerParent = markerEl.closest('.closeby-marker')
      if (markerParent) markerParent.classList.remove('hover')
    }
  }
}

export const handleLocationActive = (location) => {
  if (location) {
    const markerEl = document.querySelector(`[data-marker-icon-id="${location.id}"]`)

    if (markerEl) {
      markerEl.classList.add('active')

      const markerParent = markerEl.closest('.closeby-marker')
      if (markerParent) markerParent.classList.add('active')
    }
  }
}
