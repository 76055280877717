import PropTypes from "prop-types";
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { triggeriFramePostMessage } from '../../services/locationHelpers';
import { getCategories, getLocationsNearby, updateLocator, fetchLocationDetails } from '../../actions/locatorActions';
import { isMobile } from '../../shared/isMobile';

import LocatorMapCluster from './LocatorMapCluster';
import LocatorList from './LocatorList';
import LocatorListMobile from './LocatorListMobile';
import LocatorLocation from './LocatorLocation';

export class LocatorWrapper extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { delinquent, mapKey } = this.props.locator;

    if (delinquent) return;

    this.props.getCategories(mapKey)
  }

  componentDidUpdate(prevProps) {
    const { embed: { pageEmbed }, locator } = this.props;

    if (prevProps.locator.locations !== locator.locations && pageEmbed) {
      triggeriFramePostMessage();
    }
  }

  getActualBoundsFromBox (boundingBox) {
    const actualBounds = this.locatorMap.getWrappedInstance().updateBounds(boundingBox)
    return [
      actualBounds.sw.lat,
      actualBounds.sw.lng,
      actualBounds.ne.lat,
      actualBounds.ne.lng
    ].join(',')
  }

  onSearch = ({ boundingBox, boundingBoxArray, coordinates, categories = [], suggestion = null }) => {
    const { cachable, locator: { mapKey } } = this.props;

    let boundingBoxStr
    if (boundingBoxArray) {
      boundingBoxStr = boundingBoxArray.join(',')
    } else if (boundingBox) {
      boundingBoxStr = this.getActualBoundsFromBox(boundingBox)
    }

    const { autosuggest_latitude, autosuggest_longitude } = this.suggestionCoordinates(suggestion)

    this.props.getLocationsNearby(mapKey, {
      cachable: cachable,
      coordinates,
      geocode: (coordinates || boundingBoxStr) ? false : true,
      categories,
      bounding_box: boundingBoxStr,
      autosuggest_name: suggestion ? (suggestion.place_name || suggestion.text) : '',
      autosuggest_latitude: autosuggest_latitude,
      autosuggest_longitude: autosuggest_longitude,
      autosuggest_places_id: suggestion && !suggestion.fixture ? suggestion.id : '',
      autosuggest_location_id: suggestion && suggestion.fixture ? suggestion.id : ''
    })
  }

  suggestionCoordinates = suggestion => {
    if (suggestion) {
      return {
        autosuggest_latitude: suggestion.fixture ? suggestion.center[0] : suggestion.center[1],
        autosuggest_longitude: suggestion.fixture ? suggestion.center[1] : suggestion.center[0],
      }
    } else {
      return {
        autosuggest_latitude: '',
        autosuggest_longitude: ''
      }
    }
  }

  invalidMapboxKey = () => {
    const { mapboxClientKey } = this.props;

    return (!mapboxClientKey.includes('pk.') || mapboxClientKey.length < 50);
  }

  get renderInvalidToken() {
    return (
      <div className="closeby-map-container-invalid-token">
        <div>Invalid Mapbox Access Token</div>
        <div className="closeby-map-container-invalid-token-copy">
          Please set a valid Mapbox access token in your <a href="https://www.closeby.co/maps/install" target="_blank">account settings</a>
        </div>
      </div>
    )
  }

  get renderClosebyLogo() {
    return (
      <a href="http://www.closeby.co" target="_blank" className="closeby-map-branding" title="Powered by Closeby.co">
        <svg className="closeby-map-branding-svg" width="128px" height="128px" viewBox="0 0 128 128" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillOpacity="0.8" strokeOpacity="0.3">
            <g transform="translate(-36.000000, -36.000000)" fill="#FFFFFF" stroke="#000000" strokeWidth="5">
              <path d="M100.129702,39.2639429 C133.692395,39.2639429 160.99546,66.5670084 160.99546,100.129702 C160.99546,133.690875 133.689425,160.99546 100.129702,160.99546 C66.5670084,160.99546 39.2639429,133.692395 39.2639429,100.129702 C39.2639429,66.5685282 66.5699781,39.2639429 100.129702,39.2639429 Z M122.973099,74.8392186 L122.973894,74.8374719 C123.30306,74.1147839 122.557255,73.3685949 121.832769,73.6949299 L65.9610892,99.0959472 C65.2036616,99.4402391 65.3322625,100.554802 66.1494066,100.718794 L87.1602498,104.919594 C89.4760859,105.389115 91.28605,107.199114 91.7505528,109.515313 L95.949676,130.522272 C96.1139121,131.341602 97.2304769,131.469635 97.5762842,130.711833 L122.973099,74.8392186 Z"></path>
            </g>
          </g>
        </svg>
      </a>
    )
  }

  get renderList() {
    const { cachable, locator, mapboxClientKey } = this.props;

    if (locator.mobileStyle === 'mobile_map_search_style' && isMobile()) {
      return (
        <LocatorListMobile
          cachable={cachable}
          mapboxClientKey={mapboxClientKey}
          onSearch={this.onSearch}
        />
      )
    } else {
      return (
        <LocatorList
          cachable={cachable}
          mapboxClientKey={mapboxClientKey}
          onSearch={this.onSearch}
        />
      )
    }
  }


  render() {
    const {
      cachable,
      doesMapGeolocate,
      defaultViewport,
      embed,
      locator,
      mapboxClientKey,
      setMapCenter = false,
    } = this.props;

    if (this.invalidMapboxKey()) return this.renderInvalidToken;

    const mobileStyleClass = isMobile() ? locator.mobileStyle : '';
    const responsiveMobileHeight = embed.responsiveMobileHeight && embed.pageEmbed;

    return (
      <div className={`closeby-map-container ${embed.pageEmbed ? 'page-embed' : ''} ${locator.listStyle} ${mobileStyleClass} ${responsiveMobileHeight ? 'responsive-mobile-height' : ''}`}>
        <LocatorMapCluster
          cachable={cachable}
          defaultViewport={defaultViewport}
          doesMapGeolocate={doesMapGeolocate}
          mapboxClientKey={mapboxClientKey}
          ref={node => this.locatorMap = node}
          setMapCenter={setMapCenter}
          onSearch={this.onSearch}
        />

        {this.renderClosebyLogo}
        {this.renderList}

        <LocatorLocation />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state
  }
};

LocatorWrapper.propTypes = {
  cachable: PropTypes.bool,
  doesMapGeolocate: PropTypes.bool.isRequired,
  locator: PropTypes.object.isRequired,
  mapboxClientKey: PropTypes.string,
};

export default connect(mapStateToProps, {
  fetchLocationDetails,
  getCategories,
  getLocationsNearby,
  updateLocator,
})(LocatorWrapper);
