import PropTypes from "prop-types";
import React, { Component } from "react";
import imgixUrl from '../../services/imgixUrl';

import { handleLocationMouseover, handleLocationMouseout } from '../../services/markerService'

export default class LocatorListItem extends Component {
  handleLocationClick = () => {
    this.props.onClick(this.props.location)
  }

  handleLocationMouseover = () => {
    handleLocationMouseover(this.props.location)
  }

  handleLocationMouseout = () => {
    handleLocationMouseout(this.props.location)
  }

  get renderDistance() {
    const { location } = this.props

    if (location.distance === null) return;

    return (
      <div className="closeby-locations-list-item-distance">
        <span className="closeby-locations-list-item-distance-icon">
          <svg width="8px" height="10px" viewBox="0 0 8 10" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none">
              <g transform="translate(-8684.000000, -6083.000000)" fill="#444444">
                <g transform="translate(8684.000000, 6083.000000)">
                  <g>
                    <polygon points="4 0 0 9.61098958 0.37345 10 4 8.33333333 7.62655 10 8 9.61098958"></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
        {location.distance}
      </div>
    )
  }

  get matches() {
    const { location, categoryColors } = this.props;

    return location.categories.map((category) => {
      const found = categoryColors.find(({ name, location_image }) => 
        name === category && location_image !== null
      );
      return found?.location_image || null;
    });
  }

  get renderBannerImage() {
    const { location, locationDefaultImage, categoryLocationImagesEnabled } = this.props;

    const imageUrl = imgixUrl(
      (location.banner_url || (categoryLocationImagesEnabled && this.matches[0]) || locationDefaultImage), 
      { w: 600 }
    );
    const url = imageUrl.startsWith('//') ? `https:${imageUrl}` : imageUrl;

    return (
      <div
        className="closeby-locations-list-item-banner"
        style={{ backgroundImage: `url("${url}")` }}
      >
        <div className="closeby-locations-list-item-banner-border" />
      </div>
    );
  }

  get renderLocationTitle() {
    const { priority, title } = this.props.location;

    return (
      <div className={`closeby-locations-list-item-title ${priority ? 'with-priority-icon' : ''}`}>
        {title}
        {priority && (
          <svg className="closeby-locations-list-item-priority" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.422377 8.73707L4.12891 12.3492L3.24743 17.4522C3.1788 17.82 3.25213 18.2002 3.45257 18.5163C3.65321 18.8322 3.9659 19.0606 4.32789 19.1552C4.69004 19.2498 5.07459 19.2039 5.40399 19.0264L9.98657 16.6004L14.5691 19.0264C14.8976 19.2009 15.2797 19.2456 15.6396 19.1518C15.9995 19.0578 16.3109 18.8319 16.5121 18.5192C16.7133 18.2063 16.7894 17.8293 16.7257 17.4629L15.8442 12.3493L19.5507 8.73716C19.8159 8.47955 19.975 8.13208 19.9973 7.76316C20.0195 7.39423 19.9031 7.03018 19.6709 6.74271C19.4387 6.45501 19.1074 6.26454 18.742 6.20849L13.6202 5.46444L11.3288 0.825252C11.1647 0.493185 10.8821 0.234614 10.5368 0.100553C10.1914 -0.0335176 9.80839 -0.0335176 9.46315 0.100553C9.11773 0.234624 8.83511 0.493166 8.67097 0.825252L6.37956 5.46444L1.25781 6.20849C0.892457 6.26452 0.561122 6.455 0.329102 6.74271C0.0968807 7.03021 -0.0195143 7.39424 0.0026768 7.76316C0.024865 8.13208 0.184129 8.47939 0.449069 8.73697L0.422377 8.73707ZM6.84864 7.30029C7.33097 7.23053 7.74822 6.92816 7.96464 6.49173L9.98639 2.39428L12.0081 6.49173C12.2246 6.92816 12.6418 7.23054 13.1241 7.30029L17.6447 7.95803L14.3749 11.1471C14.0256 11.4878 13.8663 11.9787 13.949 12.4599L14.72 16.9643L10.6765 14.84C10.2446 14.6123 9.72824 14.6123 9.29631 14.84L5.25282 16.9643L6.02378 12.4599C6.10652 11.9787 5.94725 11.4878 5.59788 11.1471L2.32795 7.95803L6.84864 7.30029Z" fill="#ffd024" />
          </svg>
        )}
      </div>
    )
  }

  render() {
    const { location, listStyle, locationDefaultImage, categoryLocationImagesEnabled } = this.props;

    const isListStyleSupported = ['image_list_style', 'column_list_style'].includes(listStyle);
    const hasBanner = location.banner_url || (categoryLocationImagesEnabled && this.matches[0]) || locationDefaultImage;
    
    const showBanner = isListStyleSupported && hasBanner;

    return (
      <div
        className={`closeby-locations-list-item ${showBanner ? 'with-banner-image' : ''}`}
        onClick={this.handleLocationClick}
        onMouseEnter={this.handleLocationMouseover}
        onMouseLeave={this.handleLocationMouseout}
      >
        {/* {this.renderDistance} */}
        {showBanner && this.renderBannerImage}
        {this.renderLocationTitle}
        <div className="closeby-locations-list-item-address">
          {this.props.location.address_full}
        </div>
      </div>
    );
  }
}
