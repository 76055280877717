import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { SUPPORT_EMAIL } from '../../constants';

export class LocatorDelinquentNotice extends Component {
  clickDelinquentLink = () => {
    const { mapKey } = this.props.locator;

    window.open(`mailto:${SUPPORT_EMAIL}?subject=Closeby%20Subscription%20Over%20Quota%20For%20${mapKey}`, '_blank')
  }

  render() {
    return (
      <div className="closeby-locations-delinquent-notice">
        <div className="closeby-locations-delinquent-notice-line1">
          Welcome to Closeby!
        </div>
        <div>
          <p>
            The final configuration step is to add your Mapbox access token on the <a href="/maps/install" target="_blank">Map Installation</a> page. Questions? Email us! team@closeby.co 
          </p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    locator: state.locator
  }
};

LocatorDelinquentNotice.propTypes = {
  locator: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {})(LocatorDelinquentNotice);
