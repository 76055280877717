import API_ENDPOINTS from '../constants/apiEndpoints';
import {
  EMBED_UPDATE,
} from '../constants';

export const updateEmbed = (values) => {
  return {
    type: EMBED_UPDATE,
    values
  };
}
