import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import Select from 'react-select';

import { updateLocator } from '../../actions/locatorActions';

export class LocatorFilterSelect extends Component {
  changeFilter = (selectedCategories) => {
    const { bounding_box } = this.props.locator;

    let categories = [];

    if (selectedCategories) {
      categories = selectedCategories.map(category => category.value);
    }

    this.props.updateLocator({ selectedCategories: categories });
    this.props.onSearch({ categories: categories, boundingBoxArray: bounding_box });
  }

  render() {
    const { categories, selectedCategories, categoryRestriction, text_overrides } = this.props.locator;

    if (!categories || categories.length === 0) return null;
    if (categoryRestriction && categoryRestriction.length) return null;

    const categoryOptions = categories.sort().map(category => ({ label: category.name, value: category.name }));
    const selectedValues = selectedCategories.map(selected => categoryOptions.find(option => option.value === selected));

    return (
      <div className="closeby-locations-filters with-dropdown">
        <Select
          name="categories"
          className="dropdown-md dropdown-select closeby-locations-filter-dropdown"
          classNamePrefix="closeby-locations-filter-dropdown"
          isMulti
          isClearable={true}
          isSearchable={false}
          menuShouldScrollIntoView
          menuPlacement={'auto'}
          onChange={this.changeFilter}
          options={categoryOptions}
          placeholder={text_overrides && text_overrides.show_categories ? text_overrides.show_categories : 'Filter Locations'}
          styles={{
            dropdownIndicator: (provided, state) => ({
              ...provided,
              transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
            })
          }}
          value={selectedValues}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    locator: state.locator
  }
};

LocatorFilterSelect.propTypes = {
  locator: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  updateLocator: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updateLocator })(LocatorFilterSelect);
