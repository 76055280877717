import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Geocoder from 'react-mapbox-gl-geocoder';

import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import find from 'lodash.find';

import { viewLocationDetails } from '../../actions/locatorActions';

export class LocatorListSearch extends Component {
  state = { error: null };

  handleScroll = () => {
    const locationsScrolledAmount = this.locatorList ? this.locatorList.scrollTop : 0
    this.setState({ locationsScrolledAmount })
  }

  setError = (error) => {
    this.setState({
      error: (error.message || 'Error with this address selection')
    })
  }

  trackGAView = (suggestion) => {
    ReactGA.event({
      category: 'Closeby-Navigation',
      action: 'Click Suggestion',
      label: suggestion.isFixture ? `Location: ${suggestion.text}` : `${suggestion.place_name}`
    });
  }

  getBoundingBoxOrCoordinates = (suggestion) => {
    let boundingBoxArray, coordinates;

    if (!suggestion.bbox) {
      coordinates = `${suggestion.center[1]},${suggestion.center[0]}`;
    } else if (suggestion.fixture) {
      coordinates = suggestion.center.join(',');
    } else {
      boundingBoxArray = [suggestion.bbox[1], suggestion.bbox[0], suggestion.bbox[3], suggestion.bbox[2]];
    }

    return { boundingBoxArray, coordinates }
  }

  clickInputIcon = () => {
    this.geoSuggestNode.blur();

    setTimeout(() => this.geoSuggestNode.focus(), 500);
  }

  onSuggestSelect = (viewport, suggestion) => {
    const { googleAnalyticsId, locations, mapKey, selectedCategories } = this.props.locator;

    if (!suggestion) return;
    if (this.state.error) this.setState({ error: null });
    if (googleAnalyticsId) this.trackGAView(suggestion);

    const { boundingBoxArray, coordinates } = this.getBoundingBoxOrCoordinates(suggestion);

    if (suggestion.fixture) {

      // If location already exists on map, simulate a location click

      const existingLocation = find(locations, { id: suggestion.id });

      if (existingLocation) {
        return this.props.viewLocationDetails(mapKey, existingLocation, googleAnalyticsId)();
      }
    }

    this.props.onSearch({
      boundingBoxArray,
      coordinates,
      suggestion,
      categories: selectedCategories
    })
  }

  localGeocoder = (query) => {
    const { fixtures } = this.props.locator;

    if (fixtures && query && query.trim().length) {

      const matchingFixtures = fixtures.filter(fixture => {
        const searchableText = fixture.searchable_text;

        if (!searchableText) return false;
        const queryWordsArray = query.toLowerCase().trim().split(/\s+/);
        let matchingString = true;

        if (queryWordsArray) {
          queryWordsArray.forEach(word => {
            if (matchingString && word) matchingString = searchableText.includes(word);
          });
        }

        return matchingString;
      });

      if (matchingFixtures.length) {
        return matchingFixtures.map(fixture => fixture.location).slice(0, 3);
      }
    }

    return [];
  }

  render() {
    const { categoryRestriction, center, listStyle, suggestionCountries, suggestionType, text_overrides } = this.props.locator;
    let lastItemWasFixture = false;

    const inputComponent = props => {
      return (
        <input
          value={props.value || ''}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          autoComplete="off"
          className="form-control closeby-locations-search-input"
          placeholder={text_overrides && text_overrides.search_placeholder ? text_overrides.search_placeholder : 'Search...'}
          ref={(node) => { this.geoSuggestNode = node }}
        />
      )
    }

    const itemComponent = suggestion => {
      const suggestionFormattedText = suggestion.item.fixture ? suggestionItemText(suggestion.children, suggestion.item.address_full) : suggestionItemText(suggestion.children);
      const showFixtureDivider = lastItemWasFixture && !suggestion.item.fixture;

      lastItemWasFixture = !!suggestion.item.fixture;

      return (
        <div>
          {showFixtureDivider && (
            <div className="closeby-locations-search-fixture-divider" />
          )}

          <div onClick={suggestion.onClick} className={`${suggestion.className} closeby-locations-search-suggestion ${suggestion.item.fixture ? 'suggestion-fixture' : ''}`}>
            {suggestion.item.fixture ? (
              <svg className="closeby-locations-search-fixture-icon" width="96px" height="92px" viewBox="0 0 96 92" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g fill="#CCCCCC" fillRule="nonzero">
                    <path d="M73.5,56.4 L94,39.5 C96.9,37.1 95.4,32.4 91.6,32.2 L65.2,30.6 C63.6,30.5 62.2,29.5 61.6,28 L51.9,3.2 C50.5,-0.3 45.6,-0.3 44.2,3.2 L34.5,27.9 C33.9,29.4 32.5,30.4 30.9,30.5 L4.4,32.1 C0.6,32.3 -0.9,37 2,39.4 L22.5,56.2 C23.7,57.2 24.3,58.9 23.9,60.4 L17.2,86.1 C16.3,89.7 20.2,92.6 23.4,90.6 L45.7,76.3 C47.1,75.4 48.8,75.4 50.1,76.3 L72.5,90.6 C75.7,92.6 79.6,89.7 78.7,86.1 L72,60.5 C71.7,59 72.2,57.4 73.5,56.4 Z"></path>
                  </g>
                </g>
              </svg>
            ) : (
              <svg className="closeby-locations-search-suggestion-icon" width="320px" height="448px" viewBox="0 0 320 448" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(-7628.000000, -6786.000000)" fill="#CCCCCC">
                    <g transform="translate(7628.000000, 6786.000000)">
                      <path d="M160,0 C71.996,0 0,70.557 0,156.801 C0,274.4 160,448 160,448 C160,448 320,274.4 320,156.801 C320,70.557 248.004,0 160,0 L160,0 Z M160,212.801 C128.004,212.801 102.856,188.156 102.856,156.801 C102.856,125.444 128.003,100.801 160,100.801 C191.997,100.801 217.144,125.444 217.144,156.801 C217.144,188.156 191.996,212.801 160,212.801 L160,212.801 Z"></path>
                    </g>
                  </g>
                </g>
              </svg>
            )}

            <span dangerouslySetInnerHTML={{ __html: suggestionFormattedText }} />
          </div>
        </div>
      )
    }

    const suggestionItemText = (text, fixtureAddress) => {
      const searchValue = this.geoSuggestNode.value;
      const strRegExp = new RegExp(searchValue, 'gi');
      const textWithHighlight = text.replace(strRegExp, searchValue => `<b>${searchValue}</b>`);

      if (fixtureAddress) {
        return `${textWithHighlight} <span class="closeby-locations-search-suggestion-address">${fixtureAddress}</span>`
      }

      return textWithHighlight ? text.replace(strRegExp, searchValue => `<b>${searchValue}</b>`) : null;
    }

    let dataTypes;

    if (suggestionType === 'suggestion_include_address') {
      dataTypes = 'country,region,postcode,district,place,locality,address';
    } else if (suggestionType === 'suggestion_include_locality') {
      dataTypes = 'country,region,district,place,locality';
    } else {
      dataTypes = 'country,region,postcode,district,place,locality';
    }

    if (categoryRestriction) return null;

    return (
      <div className={this.props.className ? this.props.className : `closeby-locations-search ${listStyle} `}>
        <Geocoder
          hideOnSelect={true}
          inputComponent={inputComponent}
          itemComponent={itemComponent}
          initialInputValue={''}
          limit={10}
          localGeocoder={this.localGeocoder}
          mapboxApiAccessToken={this.props.mapboxClientKey}
          onSelected={this.onSuggestSelect}
          viewport={{}}
          queryParams={{
            fuzzyMatch: true,
            proximity: center ? { latitude: center.lat, longitude: center.lng } : null,
            types: dataTypes,
            country: suggestionCountries
          }}
          updateInputOnSelect={true}
        />
        <label className="closeby-locations-search-icon" onClick={this.clickInputIcon}>
          <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
            <g stroke="none" fill="none">
              <g transform="translate(-431.000000, -12406.000000)" fill="#000000">
                <g transform="translate(431.000000, 12406.000000)">
                  <path d="M21.9140625,18.28125 C23.0859375,16.4375 23.7734375,14.25 23.7734375,11.8984375 C23.7734375,5.328125 18.453125,0 11.890625,0 C5.3203125,0 0,5.328125 0,11.8984375 C0,18.46875 5.3203125,23.796875 11.8828125,23.796875 C14.265625,23.796875 16.484375,23.09375 18.34375,21.890625 L18.8828125,21.515625 L27.3671875,30 L30,27.3203125 L21.5234375,18.8359375 L21.9140625,18.28125 L21.9140625,18.28125 Z M18.546875,5.25 C20.3203125,7.0234375 21.296875,9.3828125 21.296875,11.890625 C21.296875,14.3984375 20.3203125,16.7578125 18.546875,18.53125 C16.7734375,20.3046875 14.4140625,21.28125 11.90625,21.28125 C9.3984375,21.28125 7.0390625,20.3046875 5.265625,18.53125 C3.4921875,16.7578125 2.515625,14.3984375 2.515625,11.890625 C2.515625,9.3828125 3.4921875,7.0234375 5.265625,5.25 C7.0390625,3.4765625 9.3984375,2.5 11.90625,2.5 C14.4140625,2.5 16.7734375,3.4765625 18.546875,5.25 L18.546875,5.25 Z" id="Shape"></path>
                </g>
              </g>
            </g>
          </svg>
        </label>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    locator: state.locator,
    embed: state.embed
  }
};

export default connect(mapStateToProps, {
  viewLocationDetails
})(LocatorListSearch);
