import Axios from 'axios'

export const getCsrfToken = () => {
  const metaEl = document.head.querySelector("meta[name=csrf-token]")
  return metaEl && metaEl.content
}

const axios = Axios.create({
  headers: {
    'Accept': 'application/json',
  }
})


axios.interceptors.request.use((config) => {
  if (config.method !== 'get') {
    const csrfToken = getCsrfToken()
    if (csrfToken) config.headers['X-CSRF-Token'] = csrfToken
  }
  return config
})

export default axios
