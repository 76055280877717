import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

export class LocatorResultsSummary extends Component {
  isLimitedResults = () => {
    return this.props.locator.is_limited_results
  }

  render() {
    const {
      isFetching,
      results_count,
      showResultsSummary,
      total_count,
      units,
      within_range,
    } = this.props.locator;

    if (isFetching || (typeof results_count === undefined || results_count < 6)) return null;
    if (!showResultsSummary) return null;

    const limitedSentence = this.isLimitedResults() ? `of ${total_count} ` : '';
    const resultsFullSentence = results_count === 1 ? `Showing ${results_count} location` : `Showing ${results_count} ${limitedSentence}locations`

    return (
      <div className="closeby-locations-pagination-section clearfix">
        <div className="closeby-locations-pagination-count">
          {results_count !== undefined ? resultsFullSentence : ''}
          {within_range && (
            <span> within {within_range} {units}</span>
          )}.
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    locator: state.locator
  }
};

LocatorResultsSummary.propTypes = {
  locator: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {})(LocatorResultsSummary);
