import differenceBy from 'lodash.differenceby';
import isEqual from 'lodash.isequal';
import moment from 'moment';

const OPEN_24_HOURS = 'Open 24 hours';
const TO_SENTENCE = 'to';

export const formatDaysOpen = (locationHours, hoursFormat, open24HoursOverride, toOverride) => {
  const open24HoursText = open24HoursOverride || OPEN_24_HOURS;
  const toSentenceText = toOverride || TO_SENTENCE;
  let formattedArray = [];
  let lastDayIterated = null;

  const formatOpenCloseTimes = (time_open, time_close) => {
    if (hoursFormat === 'format_24_hours') {
      return `${time_open} - ${time_close}`;
    } else {
      const formattedOpen = moment(time_open, "HH:mm").format("h:mm A", { trim: 'small' });
      const formattedClose = moment(time_close, "HH:mm").format("h:mm A", { trim: 'small' });
      return `${formattedOpen} - ${formattedClose}`;
    }
  }

  locationHours.forEach(locationHour => {
    locationHour._dayCount = locationHour.day;

    if (lastDayIterated && locationHour._dayCount === lastDayIterated._dayCount && lastDayIterated.formatted_time !== [open24HoursText]) {
      // If multiple open hours on same day
      const existingDayObject = formattedArray[formattedArray.length - 1];
      const existingOpenTime = existingDayObject.time_open;
      const existingOpenHour = parseInt(existingOpenTime.split(':')[0], 10);
      const newOpenHour = locationHour.time_open ? parseInt(locationHour.time_open.split(':')[0], 10) : 24;
      const newOpenTime = formatOpenCloseTimes(locationHour.time_open, locationHour.time_close);

      if (existingOpenHour < newOpenHour) {
        existingDayObject.formatted_time.push(newOpenTime);
      } else if (existingOpenTime !== newOpenTime) {
        existingDayObject.formatted_time.unshift(newOpenTime);
        existingDayObject.time_open = existingOpenTime;
      }
    } else if (locationHour.time_open === '00:00' && locationHour.time_close === '00:00') {
      // If open 24 hours
      locationHour.formatted_time = [open24HoursText];
      locationHour.formatted_day = locationHour.day_full_name;
      locationHour.time_open = locationHour.time_open;
      formattedArray.push(locationHour);
    } else if (locationHour.time_open === 'CLOSED') {
      // If closed this day
      locationHour.time_open = locationHour.time_open;
      locationHour.formatted_time = [locationHour.time_close];
      locationHour.formatted_day = locationHour.day_full_name;
      formattedArray.push(locationHour);
    } else {
      locationHour.formatted_time = [formatOpenCloseTimes(locationHour.time_open, locationHour.time_close)];
      locationHour.formatted_day = locationHour.day_full_name;
      locationHour.time_open = locationHour.time_open;
      formattedArray.push(locationHour);
    }

    lastDayIterated = locationHour;
  })

  let weekdays = differenceBy(formattedArray, [{ day: 0 }, { day: 6 }], 'day');
  let saturday = differenceBy(formattedArray, [{ day: 0 }, { day: 1 }, { day: 2 }, { day: 3 }, { day: 4 }, { day: 5 }], 'day');
  let sunday = differenceBy(formattedArray, [{ day: 1 }, { day: 2 }, { day: 3 }, { day: 4 }, { day: 5 }, { day: 6 }], 'day');

  let finalArray = [];

  if (weekdays.length) finalArray = finalArray.concat(combineArrayOfDays(weekdays, toSentenceText));
  if (saturday.length) finalArray = finalArray.concat(saturday);
  if (sunday.length) finalArray = finalArray.concat(sunday);

  return finalArray;
}

const combineArrayOfDays = (arrayOfDays, toSentenceText) => {
  let formattedDays = [];
  let lastDayIterated = null;

  const hoursInDayMatch = day => {

    // If day formatted_time is same as previous day, combine items

    if (lastDayIterated && (lastDayIterated._dayCount + 1) === day._dayCount) {
      const thisTimeArray = day.formatted_time;
      const prevTimeArray = lastDayIterated.formatted_time;

      if (isEqual(thisTimeArray, prevTimeArray)) {
        let prevDay = formattedDays[formattedDays.length - 1];

        formattedDays[formattedDays.length - 1].formatted_day = `${prevDay.day_full_name} ${toSentenceText} ${day.day_full_name}`;
        formattedDays[formattedDays.length - 1]._dayCount = day._dayCount;

        return;
      }
    }

    formattedDays.push(day);
    lastDayIterated = day;
  }

  arrayOfDays.forEach(day => hoursInDayMatch(day));

  return formattedDays;
}

export const triggeriFramePostMessage = (height) => {
  // Notify parent iframe to resize height if mobile device
  let iframeHeight = height;
  const embedEl = document.getElementsByClassName('closeby-overlay')[0];

  if (!embedEl) return;
  if (!iframeHeight) iframeHeight = embedEl.offsetHeight;

  window.parent.postMessage({ action: 'resizeMobileHeight', pageHeight: iframeHeight }, '*');
}
