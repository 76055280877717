import {
  EMBED_UPDATE,
} from '../constants';

import createReducer from '../shared/createReducer';

const embedReducer = createReducer(
  'embed',
  {
    EMBED_UPDATE: (state, action) => {
      return {
        ...state,
        ...action.values
      }
    },
  }, {}
);

export default embedReducer;
