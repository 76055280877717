import React from 'react';
import Slider from 'react-slick';
import PropTypes from "prop-types";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const LocatorCarousel = ({ 
  images = [] 
}) => {
  const settings = {
    dots: false,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: images.length > 1
  };

  return (
    <div className="image-slider-container">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image?.url} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

LocatorCarousel.propTypes = {
  images: PropTypes.array
};

export default LocatorCarousel;
