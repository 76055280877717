import {
  LOCATION_SHOW_FORM,
  LOCATION_HIDE_FORM,
  LOCATION_UPDATE_FORM_VALUES,
  LOCATION_SAVE_FORM_VALUES
} from '../constants';

import createReducer from '../shared/createReducer';

const locationReducer = createReducer(
  'locationForm',
  {
    LOCATION_SHOW_FORM: (state, action) => {
      let formValues = (!action.values.id || action.values.id === '') ? defaultFormValues(action.values) : action.values;
      return {
        ...state,
        ...formValues
      }
    },
    LOCATION_HIDE_FORM: (state, action) => {
      return {
        ...state,
        ...action.values
      }
    },
    LOCATION_UPDATE_FORM_VALUES: (state, action) => {
      return {
        ...state,
        ...action.values
      }
    },
    LOCATION_SAVE_FORM_VALUES: (state, action) => {
      return {
        ...state
      }
    }
  }, {}
);

const defaultFormValues = (values) => {
  let defaultValues = Object.assign({}, values, {
    address_full: '',
    banner_url: '',
    categories: [],
    created_at: '',
    custom_button_enabled: false,
    custom_button_text: '',
    custom_button_url: '',
    didi: '',
    deliveroo: '',
    doordash: '',
    grubhub: '',
    id: '',
    just_eat: '',
    latitude: '',
    location_hours: [],
    longitude: '',
    phone_number: '',
    postmates: '',
    rappi: '',
    sample: false,
    short_description: '',
    showing: true,
    status: '',
    swiggy: '',
    title: '',
    updated_at: '',
    website: '',
    welcome_text_json: undefined,
    welcome_text: '',
    uber_eats: '',
    zomato: '',
    secondary_images: [],
  });
  return defaultValues;
}

export default locationReducer;
