import {
  GET_LOCATIONS,
  LOCATIONS_UPDATE_LIST
} from '../constants';

import createReducer from '../shared/createReducer';

const locationsReducer = createReducer(
  'locations',
  {
    GET_LOCATIONS: (state, action) => {
      let locationsList = {
        ...action.json,
        isFetching: false
      }
      return {
        ...state,
        ...locationsList
      }
    },
    LOCATIONS_UPDATE_LIST: (state, action) => {
      return {
        ...state,
        ...action.values
      }
    }
  }, {}
);

export default locationsReducer;
