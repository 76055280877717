import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import mapboxgl from 'mapbox-gl'
import ReactGA from 'react-ga4'

import { debounce } from '../../shared/debounce'
import { isMobile } from '../../shared/isMobile'
import { textOverride } from '../../services/textOverridesService'

import { updateLocator, getLocationsNearby, fetchLocationDetails } from '../../actions/locatorActions'
import { popupDetailDays, popupDetailPhone, popupDetailWebsite } from './LocatorPopupDetails'
import defaultPinImage from 'images/shared/default-pin.png';

const DEFAULT_CENTER = [-122.425, 37.776]

export class LocatorMapCluster extends Component {
  constructor(props) {
    super(props)

    this.isInitialLoad = true;
    this.mapboxEventsInitiated = false;
    this.currentMarkers = [];
    this.existing10Locations = [];
    this.prevLocation = null;
    this.popUped = false;
  }

  componentDidMount() {
    const { doesMapGeolocate, setMapCenter } = this.props;
    const { delinquent, scrollZoomEnabled, showFullscreen, style, defaultViewport, enableSingleFingerScroll } = this.props.locator;

    this.mapPadding = window.outerWidth < 768 ? 15 : 50;

    if (!delinquent) {
      mapboxgl.accessToken = this.props.mapboxClientKey;

      let mapboxParams = {
        attributionControl: false,
        container: this.mapContainer,
        style: style,
        zoom: 12,
        maxZoom: 16,
        ...(enableSingleFingerScroll ? {} : {
          cooperativeGestures: true,
          locale: { 
            'TouchPanBlocker.Message': 'Use two fingers to move the map'
          }
        }),
        // projection: 'globe', // currently does not support getBounds() so disabled for now
      };

      if (setMapCenter && !doesMapGeolocate) {
        mapboxParams['center'] = DEFAULT_CENTER;
      }

      if (defaultViewport) {
        mapboxParams['bounds'] = new mapboxgl.LngLatBounds(
          new mapboxgl.LngLat(defaultViewport.sw.lng, defaultViewport.sw.lat),
          new mapboxgl.LngLat(defaultViewport.ne.lng, defaultViewport.ne.lat)
        );
      }

      window.MAP = this.map = new mapboxgl.Map(mapboxParams);

      if (!scrollZoomEnabled) this.map.scrollZoom.disable();
      if (showFullscreen) {
        this.map.addControl(new mapboxgl.FullscreenControl(), 'bottom-right');
      }

      this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');

      if (isMobile() && enableSingleFingerScroll) {
        this.map.dragPan.enable();
      } else if (isMobile() && !enableSingleFingerScroll) {
        this.onlyZoomOnTwoTouch();
      }

      this.map.on('load', () => {
        return doesMapGeolocate ? this.requestUserLocation() : this.loadInitialMapResults();
      });
    }
  }

  componentWillUnmount() {
    if (this.map) this.map.remove()
  }

  componentDidUpdate() {
    const { bounding_box, delinquent, location, locationReposition, locations, autosuggest_places_id, enableSingleFingerScroll } = this.props.locator;

    const autosuggestSelected = autosuggest_places_id && this.locationsAreChanging(locations);

    if (!delinquent && (this.locationsAreChanging(locations) || this.lastUsedBoundingBox !== bounding_box)) {
      this.existing10Locations = locations.slice(0, 10);
      this.setLocationsToMap(locations);
      this.setMapCenterFromUserCoordinates();

      this.lastUsedBounds = this.map.getBounds();
      this.lastUsedBoundingBox = bounding_box;

      if (bounding_box && (!locations.length || autosuggestSelected)) {
        this.updateBounds({
          sw: { lng: bounding_box[1], lat: bounding_box[0] },
          ne: { lng: bounding_box[3], lat: bounding_box[2] }
        })
      }
    }

    if (location) {
      // Ensure popup is shown for selected location
      this.generatePopupForLocation(location.properties, null, locationReposition);
    } else {
      // Ensure popup is removed from map
      const elements = document.getElementsByClassName('mapboxgl-popup');
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }

      this.prevLocation = null;
      this.popUped = false;
    }

    if (isMobile() && !enableSingleFingerScroll) this.onlyZoomOnTwoTouch();

    this.saveMapCenterCoordinates();
  }

  updateBounds(boundsObj) {
    const { sw, ne } = boundsObj
    const bounds = new mapboxgl.LngLatBounds(
      new mapboxgl.LngLat(sw.lng, sw.lat),
      new mapboxgl.LngLat(ne.lng, ne.lat)
    )

    this.map.fitBounds(bounds, { padding: this.mapPadding, duration: 0 }, { isFitBoundsEvent: true });
    const updated = this.map.getBounds()
    const actualBounds = {
      sw: { lat: updated._sw.lat, lng: updated._sw.lng },
      ne: { lat: updated._ne.lat, lng: updated._ne.lng }
    }

    return actualBounds
  }

  onlyZoomOnTwoTouch = () => {
    const isTouchEvent = e => e.originalEvent && 'touches' in e.originalEvent;
    const isTwoFingerTouch = e => e.originalEvent.touches.length >= 2;

    let overlaid = false;

    this.map.on('dragstart', event => {
      if (isTouchEvent(event) && !isTwoFingerTouch(event)) {
        this.map.dragPan.disable();
      }
      overlaid = false;
    });

    this.map.on('touchstart', event => {
      if (isTouchEvent(event) && !isTwoFingerTouch(event)) {

        const mapContainer = this.map.getContainer();
        const blockerDiv = mapContainer.querySelector('.mapboxgl-touch-pan-blocker');
        if (!overlaid) {
          if (blockerDiv) {
            blockerDiv.classList.add('mapboxgl-touch-pan-blocker-show');
          }
          overlaid = true;
        }

        setTimeout(() => {
          blockerDiv.classList.remove('mapboxgl-touch-pan-blocker-show');
          overlaid = false;
        }, 5000);
      }
      if (isTouchEvent(event) && isTwoFingerTouch(event)) {
        this.map.dragPan.enable();
      }
    });
  }

  handleMapMove = (evt) => {
    const { isFetching, autoRefresh } = this.props.locator

    if (evt.isFitBoundsEvent) return
    if (isFetching || !this.lastUsedBounds) return

    const updatedBounds = this.map.getBounds()

    if (autoRefresh) {
      this.refreshMapResults();
    } else if (this.mapBoundsHaveChanged(updatedBounds)) {
      this.props.updateLocator({ showRefreshList: true });
    }
  }

  requestUserLocation = () => {
    // If map setting default is to "geolocate"

    if (window.navigator.geolocation) {
      // Geolocation API supported by browser
      window.navigator.geolocation.getCurrentPosition(this.handleGeolocateSuccess, this.handleGeolocateError);
    } else {
      this.handleGeolocateError();
    }
  }

  handleGeolocateSuccess = (results) => {
    // Browser geolocation enabled and successful
    // Use new coordinates to animate to new mapbox center and refresh results

    const { mapKey, selectedCategories } = this.props.locator

    const center = {
      lat: results.coords.latitude,
      lng: results.coords.longitude
    };

    this.map.flyTo({
      center: center,
      maxDuration: 500,
      speed: 1.5,
      zoom: 10
    })

    this.props.updateLocator({ center });
    this.showUserLocation(results.coords.longitude, results.coords.latitude);

    setTimeout(() => this.refreshMapResults(), 500);

    this.map.on('move', debounce(this.handleMapMove, 500));
  }

  handleGeolocateError = () => {
    // Browser geolocation rejected or unsupported
    // Fetch locations based on default viewport
    this.loadInitialMapResults();
  }

  getActualBoundsFromBox = (boundingBox) => {
    const actualBounds = this.updateBounds(boundingBox)
    return [
      actualBounds.sw.lat,
      actualBounds.sw.lng,
      actualBounds.ne.lat,
      actualBounds.ne.lng
    ].join(',')
  }

  showUserLocation = (lng, lat) => {
    let markerEl = document.createElement('div');
    markerEl.className = 'closeby-user-marker';
    markerEl.dataset.id = 'user-id';
    markerEl.innerHTML = `<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19" cy="19" r="19" fill="#4D93FB" fill-opacity="0.1"/><circle cx="19" cy="19" r="6.21875" fill="#4D93FB" stroke="white" stroke-width="3"/></svg>`;

    const mbMarker = new mapboxgl.Marker(markerEl)
      .setLngLat([lng, lat]);

    mbMarker.addTo(this.map)
  }

  loadInitialMapResults = () => {
    const {
      cachable,
      defaultViewport,
      locator: {
        defaultLocationId,
        defaultPosition,
        mapKey,
        selectedCategories
      }
    } = this.props;

    this.props.getLocationsNearby(
      mapKey,
      {
        cachable: cachable,
        bounding_box: defaultViewport && defaultPosition !== 'show_all' ? this.getActualBoundsFromBox(defaultViewport) : null,
        categories: selectedCategories,
        isInitialLoad: this.isInitialLoad,
      }
    ).then((json) => {
      if (defaultLocationId) {
        // If user is passing in a default location ID, fetch details and show on map

        this.props.fetchLocationDetails(defaultLocationId).then(() => {
          this.selectDefaultLocationFromLink(this.props.locator.location);
        });
      } else {
        // Render default locations on map

        this.setLocationsToMap(json.locations);
        this.existing10Locations = json.locations.slice(0, 10);

        setTimeout(() => {
          const boundsArray = this.calcBoundsFromCoordinates(json.locations);
          this.map.fitBounds(boundsArray, { padding: this.mapPadding, duration: 0 });
        }, 500);

        setTimeout(() => {
          this.map.on('move', debounce(this.handleMapMove, 500));
        }, 1000);
      }
    });
  }

  selectDefaultLocationFromLink = (location) => {
    const suggestion = {
      center: [location.latitude, location.longitude],
      fixture: true,
      id: location.id,
    };

    const coordinates = `${location.lng},${location.lat}`;

    this.props.onSearch({
      boundingBoxArray: null,
      coordinates,
      suggestion
    });
  }

  refreshMapResults = () => {
    const { mapKey, selectedCategories } = this.props.locator
    const updatedBounds = this.map.getBounds()
    const { _sw, _ne } = updatedBounds

    this.lastUsedBounds = updatedBounds;

    this.props.getLocationsNearby(mapKey, {
      bounding_box: [_sw.lat, _sw.lng, _ne.lat, _ne.lng].join(','),
      geocode: false,
      categories: selectedCategories,
      isInitialLoad: this.isInitialLoad,
    });

    this.isInitialLoad = false;
  }

  setMapboxClusterMarkerSettings = () => {
    const { primaryColor, enableLocationCluster, categoryColors } = this.props.locator;
    if (this.mapboxEventsInitiated) return;

    this.mapboxEventsInitiated = true;

    this.map.on('mouseenter', 'unclustered-point', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });

    this.map.on('mouseleave', 'unclustered-point', () => {
      this.map.getCanvas().style.cursor = '';
    });

    if (enableLocationCluster) {
      // Show pointer cursor when hovering over a cluster
      this.map.on('mouseenter', 'clusters', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });

      this.map.on('mouseleave', 'clusters', () => {
        this.map.getCanvas().style.cursor = '';
      });

      // Add layer for marker cluster circles

      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'closeby-locations',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': [
            'step',
            ['get', 'point_count'],
            primaryColor || '#4d78b9',
            100,
            primaryColor || '#4d78b9',
            750,
            primaryColor || '#4d78b9',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      // Add layer for marker cluster counter
      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'closeby-locations',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          "text-color": "#ffffff"
        }
      });

      // Zoom to a cluster on click
      this.map.on('click', 'clusters', (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });

        const clusterId = features[0].properties.cluster_id;

        this.map.getSource('closeby-locations').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            this.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }
        );
      });
    }

    // When a click event occurs on a feature in
    // the unclustered-point layer, open a popup at
    // the location of the feature, with
    // description HTML from its properties.

    this.map.on('click', 'unclustered-point', (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice();
      const locationProperties = e.features[0].properties;

      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      this.generatePopupForLocation(locationProperties, coordinates);
    });

    categoryColors.forEach((category) => {
      this.map.on('click', `unclustered-point-${category.name}`, (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const locationProperties = e.features[0].properties;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        if (!this.popUped) {
          this.generatePopupForLocation(locationProperties, coordinates);
          this.popUped = true;
        }
      });
    });

    this.setMarkerStyles();
  }

  setMarkerStyles = () => {
    const {
      enableLocationCluster,
      markerBackgroundUrl,
      markerStyle,
      primaryColor
    } = this.props.locator;

    // Set markers for individual locations
    let baseLayerSettings = { source: 'closeby-locations' };

    if (enableLocationCluster) {
      baseLayerSettings = {
        ...baseLayerSettings,
        filter: ['!', ['has', 'point_count']],
      }
    }

    if (markerStyle === 'marker_custom') {
      // Custom marker images based on category
      const categoryImages = this.categoryImagesForMapbox(markerBackgroundUrl);
      const allImages = { ...categoryImages, defaultCloseBy: markerBackgroundUrl };

      Object.keys(allImages).forEach(category => {
        const imageUrl = allImages[category].startsWith('http') ? allImages[category] : `https://${allImages[category]}`;

        this.map.loadImage(imageUrl, (error, image) => {
          if (error) throw error;

          const imageHeight = image.height;
          const iconRelativeHeight = 40 / parseFloat(imageHeight);

          this.map.addImage(`custom-marker-${category}`, image);

          this.map.addLayer({
            ...baseLayerSettings,
            id: `unclustered-point-${category}`,
            type: 'symbol',
            filter: ['==', ['get', 'category'], category],
            layout: {
              'icon-image': `custom-marker-${category}`,
              'icon-size': iconRelativeHeight,
              'icon-allow-overlap': true,
              'text-allow-overlap': true,
            }
          });

          this.map.on('mouseenter', `unclustered-point-${category}`, () => {
            this.map.getCanvas().style.cursor = 'pointer';
          });
          
          this.map.on('mouseleave', `unclustered-point-${category}`, () => {
            this.map.getCanvas().style.cursor = '';
          });
        });
      });

    } else if (markerStyle === 'marker_circle') {
      // Circle marker without clustering

      const categoryColorsForMapbox = this.categoryColorsForMapbox();

      this.map.addLayer({
        ...baseLayerSettings,
        id: 'unclustered-point',
        type: 'circle',
        paint: {
          'circle-color': categoryColorsForMapbox ? [
            'match',
            ['get', 'category']
          ].concat(categoryColorsForMapbox) : primaryColor || '#11b4da',
          'circle-radius': 5,
          'circle-stroke-width': 2,
          'circle-stroke-color': 'rgba(255,255,255,.5)',
        }
      });
    } else {
      // Default marker pin

      this.map.loadImage(defaultPinImage,
        (error, image) => {
          // Load image for default marker pin then render layers

          if (error) throw error;

          this.map.addImage('custom-marker', image, { sdf: true });

          // Note: hiding shadow layer for now since they load differently than the pins
          this.map.addLayer({
            ...baseLayerSettings,
            id: 'unclustered-point-shadow',
            type: 'circle',
            paint: {
              'circle-radius': 16,
              'circle-color': '#000',
              'circle-blur': 5,
              'circle-translate': [0, 6],
            }
          });

          const categoryColorsForMapbox = this.categoryColorsForMapbox();

          this.map.addLayer({
            ...baseLayerSettings,
            id: 'unclustered-point',
            type: 'symbol',
            layout: {
              'icon-image': 'custom-marker',
              'icon-size': 0.28,
              'icon-allow-overlap': true,
              'text-allow-overlap': true,
            },
            paint: {
              'icon-color': categoryColorsForMapbox ? [
                'match',
                ['get', 'category']
              ].concat(categoryColorsForMapbox) : primaryColor || '#11b4da',
            }
          });
        }
      );
    }
  }

  categoryColorsForMapbox = () => {
    const { categoryColors, categoryColorsEnabled, primaryColor } = this.props.locator;

    if (!categoryColorsEnabled) return false;

    if (categoryColors && categoryColors.length) {
      let categoryColorsAsArray = [primaryColor || '#11b4da'];

      var uniqueCategoryColors = categoryColors.reduce((unique, o) => {
        if (!unique.some(obj => obj.name === o.name && obj.color === o.color)) {
          unique.push(o);
        }
        return unique;
      }, []);

      uniqueCategoryColors.map((category) => {
        return categoryColorsAsArray.unshift(category.name, category.color);
      });

      return categoryColorsAsArray;
    } else {
      return false;
    }
  };

  categoryImagesForMapbox = (markerBackgroundUrl) => {
    const { categoryColors, categoryImagesEnabled } = this.props.locator;
    const categoryImagesObject = {};

    if (!categoryImagesEnabled) {
      categoryColors.forEach((category) => {
        if (category.name) {
          if (!markerBackgroundUrl?.startsWith('http')) {
            categoryImagesObject[category.name] = `https://${markerBackgroundUrl}`;
          } else {
            categoryImagesObject[category.name] = markerBackgroundUrl;
          }
        }
      });
    }

    if (categoryImagesEnabled && categoryColors && categoryColors.length) {
      categoryColors.forEach((category) => {
        let categoryUrl = category?.marker_background_url || markerBackgroundUrl;

        if (!categoryUrl?.startsWith('http')) {
          categoryUrl = `https://${categoryUrl}`;
        }

        if (category.name) {
          categoryImagesObject[category.name] = categoryUrl;
        }
      });
    }

    return categoryImagesObject;
  };

  generatePopupForLocation = (locationProperties, coordinates = null, changeMapPosition = false) => {
    const { googleAnalyticsId, locations } = this.props.locator;

    const location = locations.find(location => location?.id === locationProperties?.id);

    if (!location) return;

    const mapCoordinates = coordinates || [location.longitude, location.latitude];

    if (!this.prevLocation || (this.prevLocation && this.prevLocation.id !== location.id)) {
      this.prevLocation = location;

      if (changeMapPosition) {
        this.map.flyTo({
          center: [location.longitude, location.latitude],
          maxDuration: 500,
          speed: 1.5,
          // zoom: 10
        })
      }

      const address_full = locationProperties.address_full;
      const title = locationProperties.title;

      let popupHTML = document.createElement('div');
      popupHTML.innerHTML = `
        <div class="closeby-popup-section">
          <div class="closeby-popup-header">
            <div class="closeby-popup-title">
              ${title}
            </div>
            <div class="closeby-popup-address">
              ${address_full}
            </div>
          </div>

          ${this.getPopupDetails(location)}
        </div>`;

      const popup = new mapboxgl.Popup({ offset: 10 }).setLngLat(mapCoordinates).setDOMContent(popupHTML).addTo(this.map);

      this.props.updateLocator({ location: location });
      this.props.fetchLocationDetails(location.id)

      if (googleAnalyticsId) {
        ReactGA.send({ hitType: 'pageview', page: `/closeby/locations/${location.slug}`, title: `${location.title} - ${location.address_full}` });
      }

      popup.off('close');
      popup.on('close', (e) => {
        const prevLocation = this.props.locator.location;

        if (prevLocation && prevLocation.id === location.id) {
          this.props.updateLocator({ location: null });

          if (this.props.locator.googleAnalyticsId) {
            ReactGA.send({ hitType: 'pageview', page: '/closeby/locations', title: 'View Locations' });
          }
        }
      });
    }
  };

  mapBoundsHaveChanged = (updatedBounds) => {
    const { _sw, _ne } = this.lastUsedBounds
    const { _sw: new_sw, _ne: new_ne } = updatedBounds

    if (
      ((Math.abs(_sw.lat) - Math.abs(new_sw.lat) >= .03) || (Math.abs(new_sw.lat) - Math.abs(_sw.lat) >= .03)) ||
      ((Math.abs(_sw.lng) - Math.abs(new_sw.lng) >= .03) || (Math.abs(new_sw.lng) - Math.abs(_sw.lng) >= .03)) ||
      ((Math.abs(_ne.lat) - Math.abs(new_ne.lat) >= .03) || (Math.abs(new_ne.lat) - Math.abs(_ne.lat) >= .03)) ||
      ((Math.abs(_ne.lng) - Math.abs(new_ne.lng) >= .03) || (Math.abs(new_ne.lng) - Math.abs(_ne.lng) >= .03))
    ) {
      return true
    } else {
      return false
    }
  }

  locationsAreChanging = (locations) => {
    const locationIds = new Set(this.existing10Locations.map(({ id }) => id));
    const missing = locations.slice(0, 20).filter(({ id }) => !locationIds.has(id));

    return missing.length > 0;
  }

  userIsNotMovingMap = () => {
    const { bounding_box, defaultViewport } = this.props.locator

    if (this.isInitialLoad && !defaultViewport) return true;

    return (!bounding_box || (bounding_box && Object.keys(bounding_box).length === 0))
  }

  doesMarkerMatchLocation(marker, location) {
    const markerData = marker.getElement().dataset;
    return markerData && parseInt(markerData.id, 10) === parseInt(location.id, 10);
  }

  getSWCoordinates = (locations) => {
    const lowestLng = Math.min(
      ...locations.map((location) => location.longitude)
    );
    const lowestLat = Math.min(
      ...locations.map((location) => location.latitude)
    );

    return [lowestLng, lowestLat];
  }

  getNECoordinates = (locations) => {
    const highestLng = Math.max(
      ...locations.map((location) => location.longitude)
    );
    const highestLat = Math.max(
      ...locations.map((location) => location.latitude)
    );

    return [highestLng, highestLat];
  }

  calcBoundsFromCoordinates = (locations) => {
    return [
      this.getSWCoordinates(locations),
      this.getNECoordinates(locations),
    ];
  }

  showPopupForMarker(marker) {
    const popup = marker.getPopup()
    if (!marker || !popup) return;
    if (!popup.isOpen()) marker.togglePopup()
  }

  closePopupForMarker(marker) {
    const popup = marker.getPopup()
    if (popup.isOpen()) marker.togglePopup()
  }

  setLocationsToMap = (locations) => {
    const { enableLocationCluster } = this.props.locator;

    setTimeout(() => {
      if (this.map.getSource('closeby-locations')) {
        // Update existing source/locations data

        this.map.getSource('closeby-locations').setData({
          'type': 'FeatureCollection',
          'features': locations,
        });
      } else {

        const isLargeDataset = locations.length > 5000;

        // Add new source/locations data

        let sourceProps = {
          type: 'geojson',
          data: {
            'type': 'FeatureCollection',
            'features': locations,
          },
          tolerance: isLargeDataset ? 2.0 : 1.0, // Default .375 - lower means it appears on different zoom levels
        }

        if (enableLocationCluster) {
          // If clustering is enabled, add cluster properties to source

          sourceProps = {
            ...sourceProps,
            cluster: true,
            clusterMaxZoom: 8, // Max zoom to cluster points on
            clusterRadius: 50,
          };
        }

        this.map.addSource('closeby-locations', {
          ...sourceProps
        });

    }
    }, 500);

    setTimeout(() => this.setMapboxClusterMarkerSettings(), 1000);
  }

  saveMapCenterCoordinates = () => {
    const { delinquent } = this.props.locator;
    if (delinquent) return;

    const center = this.map.getCenter();

    if (!this.props.locator.center || this.props.locator.center.lng !== center.lng) {
      this.props.updateLocator({ center: center });
    }
  }

  setMapCenterFromUserCoordinates = () => {
    const {  user_coordinates } = this.props.locator

    if (user_coordinates) {
      try {
        this.map.setCenter(user_coordinates)
      } catch (e) { console.error('Error setting user_coordinates', user_coordinates, e) }
    }
  }

  getPopupDetails(location) {
    const { locationDetailPreference, text_overrides } = this.props.locator;
    const openText = textOverride(text_overrides, 'open_hours_available', 'Open');
    let popupHours;

    if (locationDetailPreference === 'details_hours_first' && location.hours_formatted_days && location.hours_formatted_days.length) {
      popupHours = popupDetailDays(location, openText);
    } else if (locationDetailPreference === 'details_phone_first' && location.phone_number && location.phone_number.length) {
      popupHours = popupDetailPhone(location);
    } else if (locationDetailPreference === 'details_website_first' && location.website && location.website.length) {
      let website = location.website;
      website = website.includes('http') || website.includes('https') ? website : `http://${website}`;
      popupHours = textOverride(text_overrides, 'website_label', website);
      return `<div class="closeby-popup-details"><a href="${website}" target="_blank">${popupHours}</a></div>`;
    }

    if (!popupHours && location.hours_formatted_days && location.hours_formatted_days.length) popupHours = popupDetailDays(location, openText);
    if (!popupHours && location.phone_number && location.phone_number.length) popupHours = popupDetailPhone(location);
    if (!popupHours && location.website && location.website.length) popupHours = popupDetailWebsite(location);

    if (popupHours && popupHours !== undefined) {
      return `<div class="closeby-popup-details">${popupHours}</div>`;
    } else {
      return '';
    }
  }

  isLimitedResults = () => {
    return this.props.locator.is_limited_results
  }

  get renderTopWarning() {
    const { text_overrides, total_count, results_count, isFetching, categoryRestriction, showRefreshList } = this.props.locator
    if (isFetching) return;

    if (this.isLimitedResults() && !showRefreshList) {
      const defaultText = `Showing ${results_count} of ${total_count} locations. ${categoryRestriction ? 'Zoom in to see more locations.' : 'Zoom in or use the search bar to see more.'}`;

      return (
        <div className="closeby-map-limited-results">
          {textOverride(text_overrides, 'locations_limit', defaultText)}
        </div>
      )
    }
  }

  get renderRefreshListButton() {
    const { showRefreshList, text_overrides } = this.props.locator

    if (showRefreshList) {
      return (
        <a className="closeby-map-refresh-results" onClick={this.refreshMapResults}>
          <svg className="closeby-map-refresh-results-icon" width="352px" height="352px" viewBox="0 0 352 352" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-2212.000000, -7756.000000)" fill="#000000">
                <g id="android-refresh" transform="translate(2212.000000, 7756.000000)">
                  <path d="M176,308 C103.403,308 44,248.595 44,176 C44,103.399 103.403,44 176,44 C212.3,44 245.299,59.4 268.406,83.601 L198,154 L352,154 L352,0 L300.302,51.702 C268.406,19.798 224.406,0 176,0 C79.203,0 0,79.203 0,176 C0,272.797 78.094,352 176,352 C257.045,352 324.287,297.866 345.401,224 L298.85,224 C280.105,273.561 231.712,308 176,308 L176,308 Z"></path>
                </g>
              </g>
            </g>
          </svg>
          {textOverride(text_overrides, 'refresh_search', 'Search this area')}
        </a>
      )
    }
  }

  get renderDelinquent() {
    const { delinquent } = this.props.locator;
    if (delinquent) {
      return <div className="closeby-map-delinquent" />;
    }
  }

  render() {
    const { location } = this.props.locator;
    const { pageEmbed, showingMobileList } = this.props.embed;

    return (
      <div className={`closeby-map ${location ? 'showing-location' : ''} ${pageEmbed ? 'page-embed' : ''} ${showingMobileList ? 'hidden' : ''}`} ref={el => this.mapContainer = el}>
        {this.renderTopWarning}
        {this.renderRefreshListButton}
        {this.renderDelinquent}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    locator: state.locator,
    embed: state.embed
  }
};

export default connect(mapStateToProps, { updateLocator, getLocationsNearby, fetchLocationDetails }, undefined, { withRef: true })(LocatorMapCluster);
