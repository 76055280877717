const API_ENDPOINTS = {
  LOCATIONS: {
    INDEX: '/locations',
    CREATE: '/locations',
    SHOW: (id) => `/locations/${ id }`,
    UPDATE: (id) => `/locations/${ id }`,
    DESTROY: (id) => `/locations/${ id }`
  },
  LOCATOR: {
    NEARBY_LOCATIONS: (id) => `/embed/${id}/locations`,
    LOCATION_FIXTURES: (id) => `/embed/${id}/location_fixtures`,
    CATEGORIES: (id) => `/embed/${id}/categories`,
  },
  MAPS_EDITOR: {
    UPDATE: (id) => `/maps/${ id }`
  },
  IMPORT_TASKS: {
    INDEX: '/import_tasks',
    CREATE: '/import_tasks',
    SHOW: (id) => `/import_tasks/${id}`
  },
  STATS: {
    INDEX: '/stats',
    UPDATE: (id) => `/stats/${ id }`,
    SUGGESTIONS: '/stats/suggestions',
    LOCATION_VIEWS: '/stats/location_views',
    DEVICES: '/stats/devices',
    CSV: '/stats/download_location_views_csv',
  },
  SHOPIFY_SCRIPT_TAGS: {
    INDEX: '/shopify_script_tags',
    CREATE: '/shopify_script_tags',
    DESTROY: '/shopify_script_tags',
    SETUP_TEMPLATES: '/shopify_script_tags/setup_shopify_templates',
    THEMES: '/shopify_script_tags/themes',
  },
  OAUTH_ACCOUNTS: {
    SHOPIFY: '/oauth_accounts/shopify',
    GOOGLE: '/oauth_accounts/google_oauth2',
    DESTROY: (id) => `/oauth_accounts/${id}`
  },
  GOOGLE_FONTS: {
    INDEX: (key) => `https://www.googleapis.com/webfonts/v1/webfonts?key=${key}`
  },
  SUBSCRIPTIONS: {
    UPDATE_CUSTOMER: '/subscriptions/update_customer'
  },
}

export default API_ENDPOINTS;
