import {
  GET_LOCATIONS_NEARBY_REQUESTED,
  GET_LOCATIONS_NEARBY_FULFILLED,
  GET_LOCATIONS_NEARBY_REJECTED,
  LOCATOR_UPDATE
} from '../constants';

import createReducer from '../shared/createReducer';

const locatorReducer = createReducer(
  'locator',
  {
    [GET_LOCATIONS_NEARBY_REQUESTED]: (state, action) => {
      return {
        ...state,
        ...{
          isFetching: true,
          errorMessage: null,
          showRefreshList: false
        }
      }
    },
    [GET_LOCATIONS_NEARBY_FULFILLED]: (state, action) => {
      let locationsList = {
        ...action.json,
        isFetching: false,
        errorMessage: null
      }
      return {
        ...state,
        ...locationsList
      }
    },
    [GET_LOCATIONS_NEARBY_REJECTED]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error && action.error.message || 'Sorry, we had an issue loading locations. Please refresh the page and try again.'
      }
    },
    [LOCATOR_UPDATE]: (state, action) => {
      return {
        ...state,
        ...action.values
      }
    },
  }, {
    fixtures: []
  }
);

export default locatorReducer;
