import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { debounce } from '../../shared/debounce';
import { clearActiveMarkers, clearHoveredMarkers } from '../../services/markerService';
import { triggeriFramePostMessage } from '../../services/locationHelpers';
import { updateEmbed } from '../../actions/embedActions';
import { updateLocator, viewLocationDetails } from '../../actions/locatorActions';
import { textOverride } from '../../services/textOverridesService';

import LocatorEmptyState from './LocatorEmptyState';
import LocatorFilterSelect from './LocatorFilterSelect';
import LocatorListItem from './LocatorListItem';
import LocatorListSearch from './LocatorListSearch';
import LocatorSpinner from './LocatorSpinner';
import LocatorDelinquentNotice from './LocatorDelinquentNotice';
import LocatorResultsSummary from './LocatorResultsSummary';

export class LocatorListMobile extends Component {
  state = {
    locationsScrolledAmount: 0,
    setScrollPostion: false,
    showingList: false,
  }

  toggleShowingList = () => {
    const { embed: { pageEmbed, showingMobileList } } = this.props;

    this.props.updateEmbed({ showingMobileList: !showingMobileList });

    if (pageEmbed) setTimeout(() => triggeriFramePostMessage(), 500);
  }

  renderListToggle() {
    const { embed: { pageEmbed, showingMobileList }, locator: { text_overrides } } = this.props;

    const showToggleText = textOverride(text_overrides, 'mobile_show_list', 'Show List');
    const hideToggleText = textOverride(text_overrides, 'mobile_hide_list', 'View Map');

    return (
      <div className={`closeby-mobile-locations-list-toggle ${pageEmbed ? 'page-embed' : ''}`} onClick={this.toggleShowingList}>
        { showingMobileList ? hideToggleText : showToggleText }
      </div>
    )
  }

  renderLoading() {
    return (
      <div className="closeby-locations-fetching">
        <LocatorSpinner />
      </div>
    )
  }

  renderErrorMessage() {
    const { errorMessage } = this.props.locator
    return (
      <div className="closeby-locations-error">
        <p>{errorMessage}</p>
      </div>
    )
  }

  renderLocations() {
    const {
      errorMessage,
      isFetching,
      googleAnalyticsId,
      listStyle,
      locations,
      mapKey,
      text_overrides,
    } = this.props.locator;

    if (errorMessage) {
      return (
        <div>
          {this.renderErrorMessage()}
        </div>
      )
    } else if (isFetching) {
      return (
        <div>
          {this.renderLoading()}
        </div>
      )
    } else if (locations.length === 0) {
      return <LocatorEmptyState text_overrides={text_overrides} />;
    } else {
      return (
        <div className={`closeby-locations-list-items ${listStyle}`}>
          {locations.map((location) => {
            return (
              <LocatorListItem
                key={location.id}
                location={location}
                listStyle={listStyle}
                onClick={this.props.viewLocationDetails(mapKey, location, googleAnalyticsId)}
              />
            )
          })}
        </div>
      )
    }
  }

  render() {
    const {
      embed: {
        showingMobileList,
      },
      locator: {
        categoryRestriction,
        delinquent,
        listStyle,
        location
      },
      mapboxClientKey,
      onSearch,
    } = this.props;

    if (location && !delinquent) return null;

    return (
      <div className={`closeby-mobile-locations-wrapper ${showingMobileList ? 'showing-list' : ''} ${listStyle}`}>
        {delinquent ? <LocatorDelinquentNotice /> : (
          <div>

            <div className="closeby-mobile-controls-section">
              <LocatorListSearch
                className="closeby-mobile-locations-search"
                mapboxClientKey={mapboxClientKey}
                onSearch={onSearch}
              />
              {this.renderListToggle()}
            </div>

            <div className={`closeby-locations-list ${categoryRestriction ? 'with-filter-restriction' : ''}`}>
              <LocatorFilterSelect onSearch={onSearch} />
              {this.renderLocations()}

              <LocatorResultsSummary />
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    locator: state.locator,
    embed: state.embed
  }
};

export default connect(mapStateToProps, {
  updateEmbed,
  updateLocator,
  viewLocationDetails
})(LocatorListMobile);
