import API_ENDPOINTS from '../constants/apiEndpoints';
import apiClient from '../services/apiClient'
import queryString from 'query-string';
import ReactGA from 'react-ga4';

import {
  GET_LOCATIONS_NEARBY_REQUESTED,
  GET_LOCATIONS_NEARBY_REJECTED,
  GET_LOCATIONS_NEARBY_FULFILLED,
  LOCATOR_UPDATE,
} from "../constants";

export const getLocationsNearby = (mapKey, params = {}) => (dispatch) => {
  const qs = queryString.stringify(params, {arrayFormat: 'comma'});
  const url = API_ENDPOINTS.LOCATOR.NEARBY_LOCATIONS(mapKey) + `?${qs}`
  dispatch(getLocationsRequested())
  return apiClient.get(url)
  .then((resp) => {
    const json = resp.data

    dispatch(getLocationsFulfilled(json))

    if (json.autosuggest_location_id && json.autosuggest_location_id.length) {
      setTimeout(() => dispatch(fetchLocationDetails(json.autosuggest_location_id)), 500)
    }


    // NOTE: The previous code below is commented out because we now load thousands of fixtures and don't need to refresh them
    if (params['isInitialLoad']) dispatch(getLocationFixtures(mapKey, {}));

    // Refresh map fixtures when map locations are updated
    // so they're sorted by closest locations
    // if (json.user_coordinates) {
    //   dispatch(getLocationFixtures(mapKey, { coordinates: `${json.user_coordinates.lat},${json.user_coordinates.lng}` }))
    // } else if (json.bounding_box) {
    //   dispatch(getLocationFixtures(mapKey, { bounding_box: json.bounding_box.join(',') }))
    // } else {
    //   dispatch(getLocationFixtures(mapKey, {}))
    // }

    return json
  }).catch((error) => dispatch(getLocationsRejected(error)))
}

export const fetchLocationDetails = id => (dispatch) => {
  return apiClient.get(API_ENDPOINTS.LOCATIONS.SHOW(id))
  .then((resp) => {
    const json = resp.data
    if (!json || !resp.data.location) return null

    dispatch(updateLocator({
      location: resp.data.location,
      isFetching: false
    }));
  })
}

export const getLocationFixtures = (mapKey, params = {}) => (dispatch) => {
  const qs = queryString.stringify(params)
  const url = API_ENDPOINTS.LOCATOR.LOCATION_FIXTURES(mapKey) + `?${qs}`
  return apiClient.get(url)
  .then((resp) => {
    const json = resp.data
    if (!json) return null

    dispatch(updateLocator({ fixtures: resp.data }));
    return json
  })
}

export const getCategories = (mapKey, params = {}) => (dispatch) => {
  const qs = queryString.stringify(params)
  const url = API_ENDPOINTS.LOCATOR.CATEGORIES(mapKey) + `?${qs}`
  return apiClient.get(url)
  .then((resp) => {
    const json = resp.data
    if (!json) return null

    dispatch(updateLocator({ categories: resp.data }));
    return json
  })
}

const getLocationsRequested = () => {
  return {
    type: GET_LOCATIONS_NEARBY_REQUESTED
  };
}

const getLocationsRejected = (error) => {
  return {
    type: GET_LOCATIONS_NEARBY_REJECTED,
    error: error && error.message || 'Something went wrong'
  };
}

const getLocationsFulfilled = (json) => {
  return {
    type: GET_LOCATIONS_NEARBY_FULFILLED,
    json
  };
}

export const updateLocator = (values) => {
  return {
    type: LOCATOR_UPDATE,
    values
  };
}

export const viewLocationDetails = (mapKey, location, googleAnalyticsId = null) => (dispatch) => {
  return () => {
    dispatch(updateLocator({ location: location }));
    dispatch(fetchLocationDetails(location.id));

    if (googleAnalyticsId) {
      ReactGA.send({ hitType: 'pageview', page: `/closeby/locations/${location.slug}`, title: `${location.title} - ${location.address_full}` });
    }
  }
}
