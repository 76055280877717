import PropTypes from 'prop-types';
import React from 'react';
import linkifyHtml from 'linkifyjs/html';

const LocatorEmptyState = ({ text_overrides }) => {
  let hasCustomText = false;

  if (text_overrides && text_overrides.no_locations && text_overrides.no_locations.length) {
    const strippedText = text_overrides.no_locations.replace(/[\r\n]+/g, '');
    hasCustomText = strippedText && strippedText !== '<p></p>';
  }

  return (
    <div className="closeby-locations-empty-notice">
      {hasCustomText ? (
        <div dangerouslySetInnerHTML={{
          __html: linkifyHtml(text_overrides.no_locations, { linkClass: 'closeby-locations-empty-notice-link' })
        }} />
      ) : (
        <div className="closeby-locations-empty-notice-default">
          No locations found in this area.
        </div>
      )}
    </div>
  )
};

LocatorEmptyState.propTypes = {
  text_overrides: PropTypes.object.isRequired,
};

export default LocatorEmptyState;
