import { combineReducers } from 'redux';

import embed from './embedReducer';
import locations from './locationsReducer';
import locationForm from './locationReducer';
import locator from './locatorReducer';
import mapsEditor from './mapsEditorReducer';
import stats from './statsReducer';

export default combineReducers({
  embed,
  locations,
  locationForm,
  locator,
  mapsEditor,
  stats,
}, window.STORE_STATE || {});
