import domain from 'getdomain';

export const popupDetailDays = (location, openText) => {
  return `<svg class="closeby-popup-details-icon" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23.099 0.699219C10.7287 0.699219 0.700195 10.729 0.700195 23.0992C0.700195 35.4707 10.7287 45.4992 23.099 45.4992C35.4705 45.4992 45.5002 35.4707 45.5002 23.0992C45.5002 10.729 35.4705 0.699219 23.099 0.699219ZM36.6761 36.677C35.6662 37.6868 34.5635 38.5698 33.3875 39.319L31.7929 36.557L30.4073 37.357L32.0028 40.1206C29.519 41.4236 26.7656 42.1661 23.9001 42.283V39.1005H22.3001V42.283C19.4345 42.1664 16.681 41.424 14.1971 40.121L15.7929 37.3569L14.4073 36.5569L12.8124 39.3194C11.6363 38.5702 10.5334 37.687 9.52348 36.677C8.51358 35.6671 7.63048 34.5643 6.88128 33.3881L9.64368 31.7932L8.84368 30.4077L6.07958 32.0035C4.77658 29.5196 4.03418 26.7661 3.91748 23.9005H7.09998V22.3005H3.91748C4.03418 19.435 4.77668 16.6815 6.07978 14.1976L8.84358 15.7934L9.64358 14.4078L6.88128 12.813C7.63048 11.637 8.51348 10.5343 9.52318 9.52447C10.5332 8.51437 11.6361 7.63117 12.8122 6.88187L14.4071 9.64427L15.7927 8.84427L14.1969 6.08027C16.681 4.77697 19.4345 4.03447 22.3 3.91797V7.10047H23.9V3.91807C26.7654 4.03497 29.5188 4.77757 32.0026 6.08067L30.4072 8.84407L31.7928 9.64407L33.3873 6.88227C34.5633 7.63147 35.666 8.51457 36.6759 9.52437C37.6859 10.5343 38.569 11.637 39.3183 12.8131L36.5564 14.4077L37.3564 15.7933L40.12 14.1977C41.4231 16.6816 42.1657 19.435 42.2824 22.3004H39.1V23.9004H42.2824C42.1657 26.7659 41.4232 29.5193 40.1202 32.0032L37.3565 30.4075L36.5565 31.7931L39.3186 33.3878C38.5693 34.5642 37.6861 35.667 36.6761 36.677Z" fill="black"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M37.5004 21.6H25.9272C25.6454 21.0701 25.2189 20.6292 24.7004 20.3292V13.5H21.5004V20.3292C20.5442 20.8826 19.9004 21.9158 19.9004 23.1C19.9004 24.8673 21.3331 26.3 23.1004 26.3C24.2429 26.3 25.2448 25.7008 25.811 24.8H37.5004V21.6Z" fill="black"/>
    </svg> ${openText} ${location.hours_formatted_days}`;
}

export const popupDetailPhone = (location) => {
  return `<svg class="closeby-popup-details-icon" width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M36.8662 27.4675C34.1996 27.4675 31.6391 27.0404 29.2933 26.2946C28.5464 26.081 27.6933 26.1873 27.1601 26.8279L22.4662 31.5207C16.386 28.4279 11.4798 23.5207 8.38601 17.4404L13.0799 12.7477C13.6131 12.2144 13.8261 11.3613 13.6131 10.6144C12.7594 8.16128 12.3329 5.60078 12.3329 2.93408C12.3329 1.76118 11.373 0.800781 10.1996 0.800781H2.73291C1.55951 0.800781 0.599609 1.76118 0.599609 2.93408C0.599609 22.9872 16.8132 39.2008 36.8662 39.2008C38.0392 39.2008 38.9996 38.2404 38.9996 37.0675V29.6008C38.9996 28.4279 38.0392 27.4675 36.8662 27.4675Z" fill="black"/>
    </svg> ${location.phone_number}`;
}

export const popupDetailWebsite = (location) => {
  let website = location.website;
  website = website.includes('http') || website.includes('https') ? website : `http://${website}`;

  return `<a href="${website}" target="_blank">${domain.origin(website)}</a>`;
}
