import { handleActions } from 'redux-actions';
import { STORE_INITIALIZE } from '../constants';

const createReducer = (key, actions, initialState = {}) => {
  return handleActions({
    STORE_INITIALIZE: (state, action) => ({
      ...state,
      ...action.payload[key]
    }),
    ...actions
  }, initialState);
}

export default createReducer;
