import {
  MAPS_EDITOR_UPDATE,
  MAPS_EDITOR_SAVE
} from '../constants';

import createReducer from '../shared/createReducer';

const mapsEditorReducer = createReducer(
  'mapsEditor',
  {
    MAPS_EDITOR_UPDATE: (state, action) => {
      return {
        ...state,
        ...action.values
      }
    },
    MAPS_EDITOR_SAVE: (state, action) => {
      return {
        ...state,
        ...action.values
      }
    },
  }, {}
);

export default mapsEditorReducer;
