import React, { Component } from 'react';

export default class LocatorSpinner extends Component {
  render() {
    return (
      <div className="closeby-loading-spinner">
        <svg className="closeby-loading-spinner-icon" width="34px" height="43px" viewBox="0 0 34 43">
          <g stroke="none" fill="none">
            <g transform="translate(1.000000, 1.000000)" stroke="#DDDDDD">
              <path d="M3.16112653,25.89 L3.15616734,25.89 L3.00612448,25.6914935 C-2.06718371,18.979513 -1.19231672,9.50784519 5.02459507,3.83870541 C11.2415069,-1.83043438 20.7534931,-1.83043438 26.9704049,3.83870541 C33.1873167,9.5078452 34.0621837,18.979513 28.988915,25.6914413 L17.7935714,40.5068954 C17.3682332,41.0684398 16.7044465,41.3983662 16,41.3983662 C15.2955535,41.3983662 14.6317668,41.0684398 14.206085,40.5064413 L3.16112653,25.89 Z M16.0003071,8.03000001 C11.7910816,8.03533676 8.38017071,11.4463498 8.375,15.655 C8.375,19.8661712 11.7888288,23.28 16,23.28 C20.2111712,23.28 23.625,19.8661712 23.625,15.655 C23.625,11.4439312 20.2113372,8.03016598 16.0003071,8.03000001 Z" id="location-loading"></path>
            </g>
          </g>
        </svg>
        <div className="closeby-loading-spinner-border"/>
        <div className="closeby-loading-spinner-border2"/>
      </div>
    )
  }
}
